<template>
  <div class="m-5">
    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="Results">
            <b-table
              ref="refParticipantsListTable"
              :fields="standingsTableCols"
              :items="standings"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :busy="isLoading"
              empty-text="No standings yet"
              primary-key="id"
              show-empty
              class="mb-0 h-100"
              table-class="h-100"
            >
              <template #cell(name)="data">
                {{ participantName(data.item) }}
              </template>

              <template #cell(rank)="data">
                <template v-if="data.index === 0">
                  <b-img
                    :src="require(`@/assets/images/icons/1stplace.png`)"
                    alt="1st place"
                    height="20"
                  />
                </template>
                <template v-else-if="data.index === 1">
                  <b-img
                    :src="require(`@/assets/images/icons/2place.png`)"
                    alt="2nd place"
                    height="20"
                  />
                </template>
                <template
                  v-else-if="
                    data.index === 2 && data.item.elapsed_time !== null
                  "
                >
                  <b-img
                    :src="require(`@/assets/images/icons/3place.png`)"
                    alt="3rd place"
                    height="20"
                  />
                </template>
                <template v-else>
                  {{ data.index + 1 }}
                </template>
              </template>

              <template #cell(elapsed_time)="data">
                {{
                  data.item.elapsed_time
                    ? millisecondsToTime(data.item.elapsed_time)
                    : "no result yet"
                }}
              </template>

              <template #cell(difference)="data">
                <template v-if="data.index === 0">
                  {{ "--:--:--.--" }}
                </template>
                <template v-else>
                  {{
                    data.item.difference
                      ? millisecondsToTime(data.item.difference)
                      : ""
                  }}
                </template>
              </template>

              <template #cell(gap)="data">
                <template v-if="data.index === 0">
                  {{ "--:--:--.--" }}
                </template>
                <template v-else>
                  {{ data.item.gap ? millisecondsToTime(data.item.gap) : "" }}
                </template>
              </template>

              <template #cell(match_history)="data">
                <b-badge
                  v-for="({matchId, result}, idx) in winHistory(data.item.participant_id)"
                  :key="idx"
                  :variant="result === 'W' ? 'light-success' : 'light-danger'"
                  class="mr-1 cursor-pointer result align-content-center"
                  @click="() => {
                    handleMatchInfoModalShow(matchId)
                  }"
                >{{ result }}</b-badge>
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-tab>

          <b-tab title="Bracket">
            <b-row
              v-if="tournament?.status_id < TournamentStatusType.IN_PROGRESS"
            >
              <b-col class="d-flex flex-column align-items-center">
                <feather-icon
                  class="m-2"
                  icon="FrownIcon"
                  size="50"
                />
                Available when tournament is started
              </b-col>
            </b-row>

            <div
              v-else
              style="height: calc(100% - 90px) !important"
            >
              <b-row
                v-if="isLoading"
                class="text-center p-2"
              >
                <b-spinner label="Loading..." />
              </b-row>

              <vue-perfect-scrollbar
                v-else
                :settings="perfectScrollbarSettings"
                class="ps-customizer-area scroll-area h-100"
              >
                <b-row class="mb-2">
                  <b-col>
                    <bracket
                      :key="1"
                      :add-grand-finale-round="true"
                      :flat-tree="winnerNodes"
                      :is-loading-match="isLoadingMatch"
                      :loading-match-id="loadingMatchId"
                      @onMatchInfoModalShow="handleMatchInfoModalShow"
                    >
                      <template #player="{ player }">
                        {{ player.name }}
                      </template>
                    </bracket>
                  </b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col>
                    <bracket
                      :key="2"
                      :flat-tree="looserNodes"
                      :show-edit-results="false"
                      :is-loading-match="isLoadingMatch"
                      :loading-match-id="loadingMatchId"
                      @onMatchInfoModalShow="handleMatchInfoModalShow"
                    >
                      <template #player="{ player }">
                        {{ player.name }}
                      </template>
                    </bracket>
                  </b-col>
                </b-row>
              </vue-perfect-scrollbar>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <match-info-modal :match="selectedMatch" />
  </div>
</template>

<script>
import {
  BBadge,
  BCol,
  BImg,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
} from 'bootstrap-vue'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import TournamentFormat from '@/constants/TournamentFormat'
import { format } from 'date-fns'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { millisecondsToTime } from '@/common/date-utils'
import Bracket from '@/components/bracket/Bracket.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import MatchInfoModal from '@/views/tournament/MatchInfoModal.vue'

export default {
  components: {
    MatchInfoModal,
    Bracket,
    BImg,
    BSpinner,
    BTable,
    BRow,
    BCol,
    BTabs,
    BTab,
    BBadge,
    VuePerfectScrollbar,
  },
  mixins: [breadcrumbManager],
  props: {},
  data() {
    return {
      TournamentStatusType,

      selectedMatch: null,
      isLoading: true,
      sortBy: 'rank',
      isSortDirDesc: false,
      bracket: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 200,
      },
      showShowResultsButton: false,
      isLoadingMatch: false,
      loadingMatchId: null,
    }
  },
  computed: {
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },

    standings() {
      if (
        this.tournament?.settings?.first_stage_structure
        === TournamentFormat.RACE
      ) {
        let results = this.tournament?.matches[0]?.results.slice(0)

        results = results?.sort((a, b) => {
          if (a.elapsed_time === null || a.elapsed_time === 0) {
            return 1
          }

          if (b.elapsed_time === null || b.elapsed_time === 0) {
            return -1
          }

          return a.elapsed_time - b.elapsed_time
        })

        return (
          results?.map((result, index, array) => ({
            ...result,
            difference:
              result.elapsed_time && array[index - 1]?.elapsed_time
                ? result.elapsed_time - array[index - 1].elapsed_time
                : 0,
            gap:
              result.elapsed_time && array[index - 1]?.elapsed_time
                ? result.elapsed_time - array[0].elapsed_time
                : 0,
          })) || []
        )
      }

      return this.tournament?.rankings || []
    },

    standingsTableCols() {
      const cols = [
        {
          key: 'rank',
          label: '#',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Participant',
          sortable: false,
          thStyle: { width: '30%' },
        },
      ]

      if (
        this.tournament?.settings?.first_stage_structure
        === TournamentFormat.RACE
      ) {
        cols.push({
          key: 'elapsed_time',
          label: 'Time',
          sortable: false,
        })

        cols.push({
          key: 'difference',
          label: 'Difference',
          sortable: false,
        })

        cols.push({
          key: 'gap',
          label: 'Gap',
          sortable: false,
        })
      } else {
        cols.push({
          key: 'match_history',
          label: 'Match history',
          sortable: true,
        })
      }

      return cols
    },
    winnerNodes() {
      return this.bracket
        ?.filter(node => node.group.index === 1)
        ?.map(node => ({
          id: node.id,
          next: node.next_match_id,
          matchId: node.match_id,
          roundId: node.round_id,
          round: node.round,
          player1: {
            id: node.opponent1?.participant?.id,
            name:
              node.opponent1?.participant?.name || node.opponent1?.name || null,
            score:
              node.result?.opponent1_score !== null
                ? node.result?.opponent1_score
                : null,
            result: node.result?.opponent1_result || null,
            myParticipant: false,
          },
          player2: {
            id: node.opponent2?.participant?.id,
            name:
              node.opponent2?.participant?.name || node.opponent2?.name || null,
            score:
              node.result?.opponent2_score !== null
                ? node.result?.opponent2_score
                : null,
            result: node.result?.opponent2_result || null,
            myParticipant: false,
          },
        }))
    },
    looserNodes() {
      return this.bracket
        ?.filter(node => node.group.index === -1)
        ?.map((node, index, array) => ({
          id: node.id,
          next: index === array.length - 1 ? null : node.next_match_id,
          matchId: node.match_id,
          roundId: node.round_id,
          round: node.round,
          player1: {
            id: node.opponent1?.participant?.id || null,
            name:
              node.opponent1?.participant?.name || node.opponent1?.name || null,
            score:
              node.result?.opponent1_score !== null
                ? node.result?.opponent1_score
                : null,
            result: node.result?.opponent1_result || null,
            myParticipant: false,
          },
          player2: {
            id: node.opponent2?.participant?.id || null,
            name:
              node.opponent2?.participant?.name || node.opponent2?.name || null,
            score:
              node.result?.opponent2_score !== null
                ? node.result?.opponent2_score
                : null,
            result: node.result?.opponent2_result || null,
            myParticipant: false,
          },
        }))
    },
  },
  async created() {
    await this.$store.dispatch(
      'tournament/fetchTournament',
      this.$route.params.id,
    )

    await this.fetchBrackets()
  },
  methods: {
    millisecondsToTime,
    format,
    participantName(standing) {
      if (standing.participant.name) {
        return standing.participant.name
      }
      return standing.participant?.participant?.name
    },
    winHistory(participantId) {
      const history = []

      // eslint-disable-next-line consistent-return,no-unused-expressions
      const participantMatches = this.tournament?.matches
        ?.filter(
          match => match.opponent1_id === participantId
            || match.opponent2_id === participantId,
        )
        ?.sort((a, b) => a.index - b.index)

      // eslint-disable-next-line no-unused-expressions
      participantMatches?.forEach(match => {
        const result = (match.winner_id === participantId) ? 'W' : 'L'
        history.push({ matchId: match.id, result })
      })

      return history
    },
    async fetchBrackets() {
      this.isLoading = true
      const { data } = await this.$api.tournament.fetchBracket(
        this.$route.params.id,
      )
      this.isLoading = false
      this.bracket = this.tournament.status_id >= TournamentStatusType.IN_PROGRESS
        ? data
        : null
    },
    async handleMatchInfoModalShow(matchId) {
      this.selectedMatch = await this.fetchMatch(matchId)

      this.$bvModal.show('match-info-modal')
    },
    async fetchMatch(matchId) {
      this.isLoadingMatch = true
      this.loadingMatchId = matchId
      const { data } = await this.$api.tournament.matchById(matchId)

      this.isLoadingMatch = false
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  &.result {
    width: 35px;
    height: 35px;
  }
}
</style>
