var render = function () {
  var _vm$tournament;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-5"
  }, [_c('b-row', [_c('b-col', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Results"
    }
  }, [_c('b-table', {
    ref: "refParticipantsListTable",
    staticClass: "mb-0 h-100",
    attrs: {
      "fields": _vm.standingsTableCols,
      "items": _vm.standings,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.isSortDirDesc,
      "busy": _vm.isLoading,
      "empty-text": "No standings yet",
      "primary-key": "id",
      "show-empty": "",
      "table-class": "h-100"
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.participantName(data.item)) + " ")];
      }
    }, {
      key: "cell(rank)",
      fn: function fn(data) {
        return [data.index === 0 ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/1stplace.png"),
            "alt": "1st place",
            "height": "20"
          }
        })] : data.index === 1 ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/2place.png"),
            "alt": "2nd place",
            "height": "20"
          }
        })] : data.index === 2 && data.item.elapsed_time !== null ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/3place.png"),
            "alt": "3rd place",
            "height": "20"
          }
        })] : [_vm._v(" " + _vm._s(data.index + 1) + " ")]];
      }
    }, {
      key: "cell(elapsed_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.elapsed_time ? _vm.millisecondsToTime(data.item.elapsed_time) : "no result yet") + " ")];
      }
    }, {
      key: "cell(difference)",
      fn: function fn(data) {
        return [data.index === 0 ? [_vm._v(" " + _vm._s("--:--:--.--") + " ")] : [_vm._v(" " + _vm._s(data.item.difference ? _vm.millisecondsToTime(data.item.difference) : "") + " ")]];
      }
    }, {
      key: "cell(gap)",
      fn: function fn(data) {
        return [data.index === 0 ? [_vm._v(" " + _vm._s("--:--:--.--") + " ")] : [_vm._v(" " + _vm._s(data.item.gap ? _vm.millisecondsToTime(data.item.gap) : "") + " ")]];
      }
    }, {
      key: "cell(match_history)",
      fn: function fn(data) {
        return _vm._l(_vm.winHistory(data.item.participant_id), function (_ref, idx) {
          var matchId = _ref.matchId,
            result = _ref.result;
          return _c('b-badge', {
            key: idx,
            staticClass: "mr-1 cursor-pointer result align-content-center",
            attrs: {
              "variant": result === 'W' ? 'light-success' : 'light-danger'
            },
            on: {
              "click": function click() {
                _vm.handleMatchInfoModalShow(matchId);
              }
            }
          }, [_vm._v(_vm._s(result))]);
        });
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-2"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Bracket"
    }
  }, [((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) < _vm.TournamentStatusType.IN_PROGRESS ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('feather-icon', {
    staticClass: "m-2",
    attrs: {
      "icon": "FrownIcon",
      "size": "50"
    }
  }), _vm._v(" Available when tournament is started ")], 1)], 1) : _c('div', {
    staticStyle: {
      "height": "calc(100% - 90px) !important"
    }
  }, [_vm.isLoading ? _c('b-row', {
    staticClass: "text-center p-2"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('vue-perfect-scrollbar', {
    staticClass: "ps-customizer-area scroll-area h-100",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('bracket', {
    key: 1,
    attrs: {
      "add-grand-finale-round": true,
      "flat-tree": _vm.winnerNodes,
      "is-loading-match": _vm.isLoadingMatch,
      "loading-match-id": _vm.loadingMatchId
    },
    on: {
      "onMatchInfoModalShow": _vm.handleMatchInfoModalShow
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref2) {
        var player = _ref2.player;
        return [_vm._v(" " + _vm._s(player.name) + " ")];
      }
    }])
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('bracket', {
    key: 2,
    attrs: {
      "flat-tree": _vm.looserNodes,
      "show-edit-results": false,
      "is-loading-match": _vm.isLoadingMatch,
      "loading-match-id": _vm.loadingMatchId
    },
    on: {
      "onMatchInfoModalShow": _vm.handleMatchInfoModalShow
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref3) {
        var player = _ref3.player;
        return [_vm._v(" " + _vm._s(player.name) + " ")];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('match-info-modal', {
    attrs: {
      "match": _vm.selectedMatch
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }